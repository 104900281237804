import request from '@/utils/request'

// 查询车辆保养信息列表
export function listUpkeep (query) {
  return request({
    url: '/iot/upkeep/list',
    method: 'get',
    params: query
  })
}

// 查询车辆保养信息详细
export function getUpkeep (id) {
  return request({
    url: '/iot/upkeep/' + id,
    method: 'get'
  })
}

// 新增车辆保养信息
export function addUpkeep (data) {
  return request({
    url: '/iot/upkeep',
    method: 'post',
    data: data
  })
}

// 修改车辆保养信息
export function updateUpkeep (data) {
  return request({
    url: '/iot/upkeep',
    method: 'put',
    data: data
  })
}

// 删除车辆保养信息
export function delUpkeep (id) {
  return request({
    url: '/iot/upkeep/' + id,
    method: 'delete'
  })
}

// 导出车辆保养信息
export function exportUpkeep (query) {
  return request({
    url: '/iot/upkeep/export',
    method: 'get',
    params: query
  })
}
